<template>
  <div>
    <div class="component-status-container" v-if="!loading">
      <div class="table-name-info" v-for="item in list" :key="item.metadata.name">
        <img :src="imageUrl[item.metadata.name]" class="status-icon" />
        <div class="content">
          <div class="name">
            {{ item.metadata.name.indexOf("etcd") > -1 ? "etcd" : item.metadata.name }}
          </div>

          <el-tooltip effect="dark" :content="mapHealth(item.conditions).message">
            <div
              class="status-text"
              :class="{ success: mapHealth(item.conditions).status, error: !mapHealth(item.conditions).status }"
            >
              {{ mapHealth(item.conditions).message }}
            </div>
          </el-tooltip>
        </div>
      </div>
    </div>

    <div v-else>
      <content-placeholders>
        <content-placeholders-text :lines="1" />
      </content-placeholders>
    </div>
  </div>
</template>

<script>
import apiServer from "@/assets/components/api-server.svg";
import controllerManager from "@/assets/components/controller-manager.svg";
import etcd from "@/assets/components/etcd.svg";
import scheduler from "@/assets/components/scheduler.svg";

import { componentStatus } from "api/monitor";
import { find } from "lodash";

export default {
  props: {
    vendor: {
      type: String
    },
    cluster: {
      type: String
    },
    region: {
      type: String
    }
  },

  data() {
    return {
      apiServer,
      controllerManager,
      etcd,
      scheduler,
      list: [],
      loading: false,
      imageUrl: {
        scheduler: scheduler,
        "controller-manager": controllerManager,
        "etcd-0": etcd,
        kubernetes: apiServer,
        "etcd-1": etcd,
        "etcd-2": etcd
      }
    };
  },

  methods: {
    getComponentStatus() {
      this.loading = true;

      componentStatus({ vendor: this.vendor, region: this.region, cluster: this.cluster }).then(response => {
        this.loading = false;
        if (response.code === 0) {
          this.list = response.data.items;
        }
      });
    },

    mapHealth(conditions) {
      let result = find(conditions, { type: "Healthy" });

      if (result) {
        if (result.status === "True") {
          return { status: true, message: "Running" };
        }
        return { status: false, message: result.message };
      } else {
        return { status: false, message: result.message };
      }
    }
  },

  mounted() {
    this.getComponentStatus();
  }
};
</script>

<style lang="scss" scoped>
@import "~@/styles/variables.scss";
@import "~@/styles/mixin.scss";

.component-status-container {
  @include flex(space-between, center, nowrap);

  .status-icon {
    width: 40px;
    height: 40px;
    margin-right: 6px;
  }

  .name {
    @include title(12px);
    @include text-overflow();
  }

  .status-text {
    font-size: 12px;
    width: 100px;
    @include text-overflow();

    &.success {
      color: $color-success;
    }

    &.error {
      color: $color-danger;
    }
  }
}
</style>
