<template>
  <div class="node-info-container">
    <div v-if="!loading">
      <div v-for="item in list" :key="item.name">
        <div
          :class="{
            ready: mapStatus('node_ready_status', item.metrics),
            'not-ready': !mapStatus('node_ready_status', item.metrics)
          }"
          class="node-info"
        >
          <a @click.prevent="toNode(item.name)" class="node-name">
            <svg-icon
              :icon-class="mapStatus('node_ready_status', item.metrics) ? 'success' : 'error'"
              class-name="node-status"
            ></svg-icon>

            {{ item.name }}
          </a>

          <div class="usage-info">
            <div class="usage-info-item" :style="{ background: mapUserInfoItemTemplateColor('cpu', item.metrics) }">
              <div class="name">{{ $t("cpu") }}({{ mapUnit("node_cpu_total", item.metrics) }})</div>
              <div class="detail">{{ mapPercent("node_cpu_usage_total", "node_cpu_total", item.metrics) }} %</div>
              <div class="detail">
                {{ mapValue("node_cpu_usage_total", item.metrics) }} /
                {{ mapValue("node_cpu_total", item.metrics) }}
              </div>
            </div>

            <div class="usage-info-item" :style="{ background: mapUserInfoItemTemplateColor('memory', item.metrics) }">
              <div class="name">{{ $t("memory") }}({{ mapUnit("node_memory_total", item.metrics) }})</div>
              <div class="detail">{{ mapPercent("node_memory_usage_total", "node_memory_total", item.metrics) }} %</div>
              <div class="detail">
                {{ mapValue("node_memory_usage_total", item.metrics) }} /
                {{ mapValue("node_memory_total", item.metrics) }}
              </div>
            </div>

            <div class="usage-info-item" :class="{ 'has-error-pods': item.faultTotal > 0 }">
              <div class="name">
                {{ $t("pod") }}
              </div>

              <el-popover placement="left" width="600" trigger="hover" v-if="item.faultTotal > 0">
                <div style="max-height: 300px; overflow-y: scroll;">
                  <div class="node-error-pods-title">
                    <svg-icon icon-class="error" class-name="node-status"></svg-icon>
                    {{ item.name }} Error Pods
                  </div>

                  <el-table :data="item.errorPods">
                    <el-table-column :label="$t('name')" prop="metadata.name" sortable>
                      <template slot-scope="scope">
                        <div class="table-name-info">
                          <div class="content">
                            <a @click="toPod(scope.row.metadata)">
                              {{ scope.row.metadata.name }}
                            </a>
                          </div>
                        </div>
                      </template>
                    </el-table-column>

                    <el-table-column :label="$t('status')">
                      <template slot-scope="scope">
                        <span class="status">
                          {{ scope.row.podStatus || "-" }}
                        </span>
                      </template>
                    </el-table-column>

                    <el-table-column label="QoS">
                      <template slot-scope="scope">
                        {{ scope.row.status.qosClass || "-" }}
                      </template>
                    </el-table-column>

                    <el-table-column :label="$t('podIP')">
                      <template slot-scope="scope">
                        <i class="el-icon-document-copy" v-clipboard:copy="scope.row.status.podIP"></i>
                        {{ scope.row.status.podIP || "-" }}
                      </template>
                    </el-table-column>
                  </el-table>
                </div>

                <div slot="reference">
                  <div class="detail">
                    Err Count:
                    <span v-if="item.faultTotal > 0">({{ item.faultTotal }})</span>
                  </div>

                  <div class="detail">
                    {{ mapValue("node_pod_usage_total", item.metrics) }}
                    /
                    {{ mapValue("node_pod_total", item.metrics) }}
                  </div>
                </div>
              </el-popover>

              <div v-else>
                <div class="detail">{{ mapPercent("node_pod_usage_total", "node_pod_total", item.metrics) }} %</div>

                <div class="detail">
                  {{ mapValue("node_pod_usage_total", item.metrics) }}/
                  {{ mapValue("node_pod_total", item.metrics) }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-else>
      <content-placeholders>
        <content-placeholders-text :lines="12" />
      </content-placeholders>
    </div>
  </div>
</template>

<script>
import { find, isObject } from "lodash";
import { nodeInfra } from "api/monitor";
import { nodePods } from "api/cluster";
import moment from "moment";

export default {
  props: {
    vendor: {
      type: String
    },
    cluster: {
      type: String
    },
    region: {
      type: String
    }
  },

  data() {
    return {
      list: [],
      total: 0,
      loading: true
    };
  },

  methods: {
    moment,

    mapUserInfoItemTemplateColor(type, metrics) {
      let percent = this.mapPercent(`node_${type}_usage_total`, `node_${type}_total`, metrics);
      let backgroundColor;

      if (percent < 50) {
        backgroundColor = "#67c23a";
      } else if (percent < 70 && percent >= 50) {
        backgroundColor = "#ff9f00";
      } else {
        backgroundColor = "#e9422a";
      }

      return backgroundColor;

      // mapPercent('node_cpu_usage_total', 'node_cpu_total', item.metrics)
      // mapPercent('node_memory_usage_total', 'node_memory_total', item.metrics)
      // mapPercent('node_pod_usage_total', 'node_pod_total', item.metrics)
    },

    findByKey(target, metrics) {
      return find(metrics, { metricName: target }) ? find(metrics, { metricName: target }) : undefined;
    },

    mapValue(target, metrics) {
      let value = "";
      isObject(this.findByKey(target, metrics)) ? (value = this.findByKey(target, metrics).value) : (value = "-");
      return value;
    },

    mapUnit(target, metrics) {
      let unit = "";
      isObject(this.findByKey(target, metrics)) ? (unit = this.findByKey(target, metrics).unit) : (unit = "");
      return unit;
    },

    mapPercent(usageTarget, totalTarget, metrics) {
      let usage = find(metrics, { metricName: usageTarget })
        ? find(metrics, { metricName: usageTarget }).value
        : undefined;
      let total = find(metrics, { metricName: totalTarget })
        ? find(metrics, { metricName: totalTarget }).value
        : undefined;

      if (usage && total) return Number(((usage / total) * 100).toFixed(2));

      return 0;
    },

    mapStatus(target, metrics) {
      return this.findByKey(target, metrics) && this.findByKey(target, metrics).value === 1 ? true : false;
    },

    toNode(name) {
      let { href } = this.$router.resolve({
        path: `/detail/Node/${name}`,
        query: { vendor: this.vendor, region: this.region, cluster: this.cluster }
      });

      window.open(href, "_blank");
    },

    async getList() {
      this.loading = true;

      let response = await nodeInfra({
        cluster: this.cluster,
        region: this.region,
        vendor: this.vendor
      });

      let promises = [];

      if (response.code === 0) {
        this.total = response.data.total;

        response.data.items.forEach(item => {
          if (item.name) promises.push(this.getNodePods(item));
        });
      }

      let data = await Promise.all(promises);

      this.list = data;

      this.loading = false;
    },

    async getNodePods(item) {
      let response = await nodePods({
        cluster: this.cluster,
        region: this.region,
        vendor: this.vendor,
        node: item.name
      });

      if (response.code === 0) {
        let completedTotal = 0;
        let runningTotal = 0;
        let faultTotal = 0;
        let pendingTotal = 0;
        let errorPods = [];

        let pods = response.data.items.map(item => {
          if (["Running", "ContainerCreating"].includes(item.podStatus)) {
            runningTotal++;
          } else if (["Completed"].includes(item.podStatus)) {
            completedTotal++;
          } else if (["Pending"].includes(item.podStatus)) {
            pendingTotal++;
          } else {
            faultTotal++;
            errorPods.push(item);
          }

          return {
            name: item.metadata.name,
            namespace: item.metadata.namespace,
            containers: item.spec.containers,
            status: item.podStatus,
            conditions: item.status.conditions,
            podIP: item.status.podIP,
            hostIP: item.status.hostIP,
            QoS: item.status.qosClass
          };
        });

        return {
          ...item,
          pods,
          runningTotal,
          completedTotal,
          faultTotal,
          pendingTotal,
          errorPods
        };
      }
    },

    toPod(metadata) {
      let { name, namespace } = metadata;
      let { href } = this.$router.resolve({
        path: `/detail/Pod/${name}`,
        query: {
          vendor: this.vendor,
          region: this.region,
          cluster: this.cluster,
          namespace: namespace
        }
      });

      window.open(href, "_blank");
    }
  },

  mounted() {
    this.getList();
  }
};
</script>

<style lang="scss">
@import "~@/styles/variables.scss";
@import "~@/styles/mixin.scss";

.node-error-pods-title {
  @include title();
}

.node-info-container {
  margin-top: 6px;
  max-height: 520px;
  height: 520px;
  overflow-y: scroll;
}

.node-info {
  .node-name {
    @include title(12px);
    @include text-overflow();
    margin-bottom: 10px;
    color: #257adf;

    .node-status {
      width: 14px;
      height: 14px;
      margin-right: 2px;
    }
  }

  .usage-info {
    @include flex(flex-start, top, nowrap);
    margin-bottom: 10px;
    box-sizing: border-box;

    .usage-info-item {
      width: 33.3%;
      margin-right: 4px;
      text-align: center;
      padding: 2px;
      background-color: #67c23a;
      color: #fff;
      font-size: 12px;

      &.has-error-pods {
        background-color: #e9422a;
        cursor: pointer;
      }
    }
  }
}
</style>
