<template>
  <div>
    <el-row :gutter="20" v-if="!loading">
      <el-col :span="4" v-for="item in list" :key="item.name">
        <div class="resource-panel" @click="toDetail(item.name)">
          <div class="total">
            {{ item.total }}
          </div>
          <div class="name">
            {{ mapTitle[item.name] }}
          </div>
        </div>
      </el-col>
    </el-row>

    <div v-else>
      <content-placeholders>
        <content-placeholders-text :lines="2" />
      </content-placeholders>
    </div>
  </div>
</template>

<script>
import { metircTarget } from "api/monitor";
import i18n from "@/lang";
import { getMilliSecond, getStep } from "utils";

export default {
  props: {
    vendor: {
      type: String
    },
    cluster: {
      type: String
    },
    region: {
      type: String
    }
  },

  data() {
    return {
      list: [],
      loading: true,

      mapTitle: {
        cluster_deployment_total: i18n.t("Deployment"),
        cluster_ingress_total: i18n.t("Ingress"),
        cluster_sts_total: i18n.t("StatefulSet"),
        cluster_svc_total: i18n.t("Service"),
        cluster_cj_total: i18n.t("CronJob")
      }
    };
  },

  methods: {
    init() {
      let query = [
        "cluster_deployment_total",
        "cluster_sts_total",
        "cluster_ingress_total",
        "cluster_svc_total",
        "cluster_cj_total"
      ].join(",");

      let start = new Date().getTime() - getMilliSecond("1h");
      let end = new Date().getTime();
      let step = getStep("1h", 50);

      metircTarget(
        { vendor: this.vendor, region: this.region, cluster: this.cluster },
        { query, start, end, step }
      ).then(response => {
        this.loading = false;
        if (response.code === 0) {
          response.data.forEach(item => {
            let total = 0;
            let data = [];

            if (item.model && item.model[0] && item.model[0].values) {
              item.model[0].values.forEach((value, index) => {
                data.push({
                  time: index,
                  value: Number(Number(value[1]).toFixed(1))
                });
              });

              total = item.model[0].values[item.model[0].values.length - 1][1];
            }

            this.list.push({
              name: item.name,
              total,
              data
            });
          });
        }
      });
    },

    toDetail(name) {
      let type = "";
      switch (name) {
        case "cluster_deployment_total":
          type = "Deployment";
          break;

        case "cluster_ingress_total":
          type = "Ingress";
          break;

        case "cluster_sts_total":
          type = "StatefulSet";
          break;

        case "cluster_cj_total":
          type = "CronJob";
          break;

        case "cluster_svc_total":
          type = "Service";
          break;
      }

      let { href } = this.$router.resolve({
        path: `/list/${type}`
      });

      window.open(href, "_blank");
    }
  },

  mounted() {
    this.init();
  }
};
</script>

<style lang="scss" scoped>
@import "~@/styles/variables.scss";
@import "~@/styles/mixin.scss";

.resource-panel {
  background-color: #f2f7f8;
  text-align: center;
  padding: 10px 0;
  cursor: pointer;

  .total {
    @include title(18px);
    color: #257adf;
  }

  .name {
    color: $color-sub;
    font-size: 12px;
    @include text-overflow();
  }
}
</style>
