<template>
  <div>
    <div class="namespace-rank-container">
      <div v-if="!loading">
        <div v-for="(item, index) in data" :key="item.namespace" class="namespace-progress-container">
          <span style="font-size: 12px;">{{ index + 1 }}. </span>
          <a class="namespace" href="javascript:;" @click="toNamespace(item.namespace)">{{ item.namespace }}</a>
          <div class="namespace-progress">
            <div class="_inner" :style="{ width: item.width }"></div>
          </div>
          <div class="usage">{{ item.usage }}</div>
        </div>
      </div>

      <div v-else>
        <content-placeholders>
          <content-placeholders-text :lines="12" />
        </content-placeholders>
      </div>
    </div>
  </div>
</template>

<script>
import { namespaceRank } from "api/monitor";
export default {
  props: {
    vendor: {
      type: String
    },
    cluster: {
      type: String
    },
    region: {
      type: String
    },
    sortby: {
      type: String
    }
  },

  watch: {
    sortby(val) {
      console.log(val);
      this.init();
    }
  },

  data() {
    return {
      loading: true,
      data: []
    };
  },

  methods: {
    init() {
      this.loading = true;
      namespaceRank({ vendor: this.vendor, region: this.region, cluster: this.cluster }, { sortby: this.sortby }).then(
        response => {
          if (response.code === 0) {
            this.data = [];

            let data = [];

            if (response.data.length > 0) {
              let max = this.sortby === "cpu" ? response.data[0].cpu : response.data[0].memory;

              response.data.forEach(item => {
                data.push({
                  namespace: item.name,
                  usage: this.sortby === "cpu" ? item.cpu + item.cpuunit : item.memory + item.memoryunit,
                  width:
                    this.sortby === "cpu"
                      ? ((item.cpu / max) * 100).toFixed(1) + "%"
                      : ((item.memory / max) * 100).toFixed(1) + "%"
                });

                this.data.push({
                  namespace: item.name,
                  usage: this.sortby === "cpu" ? item.cpu + item.cpuunit : item.memory + item.memoryunit,
                  width:
                    this.sortby === "cpu"
                      ? ((item.cpu / max) * 100).toFixed(1) + "%"
                      : ((item.memory / max) * 100).toFixed(1) + "%"
                });
              });
            }

            this.loading = false;
          }
        }
      );
    },

    toNamespace(namespace) {
      let query = { vendor: this.vendor, region: this.region, cluster: this.cluster };

      this.$router.push({
        path: `/detail/Namespace/${namespace}`,
        query
      });
    }
  },

  mounted() {
    this.init();
  }
};
</script>

<style lang="scss" scoped>
@import "~@/styles/variables.scss";
@import "~@/styles/mixin.scss";

.namespace-rank-container {
  margin-top: 6px;
  max-height: 482px;
  height: 482px;
  overflow-y: scroll;
}

.namespace-progress-container {
  @include flex(space-between, center, nowrap);

  margin-bottom: 10px;

  .namespace,
  .usage {
    font-size: 12px;
    width: 60px;
    @include text-overflow();
  }

  .namespace {
    color: $color-main;

    &:hover {
      color: $color-primary;
    }
  }

  .usage {
    color: #257adf;
  }

  .namespace-progress {
    @include progress();
    flex: 1;
    background-color: #f2f7f8;
    height: 12px;

    margin: 0 10px;

    ._inner {
      background-color: #257adf;
    }
  }
}
</style>
