<template>
  <div>
    <el-row :gutter="20">
      <!-- <el-col :md="24" :lg="6" :sm="24" :xs="24">
        <div class="dashboard-card" style="margin-bottom: 10px;">
          <div class="title">
            {{ $t("currentMonthCost") }}
          </div>

          <div class="cost-container" v-if="!costLoading">
            <span class="money">${{ currentMonthCost }}</span>
            <span class="previous-month">
              {{ $t("previousMonthCost") }}：
              <span class="money">${{ previousMonthCost }}</span>
            </span>
          </div>

          <div v-else>
            <content-placeholders>
              <content-placeholders-text :lines="1" />
            </content-placeholders>
          </div>
        </div>
      </el-col> -->

      <el-col :md="24" :lg="12" :sm="24" :xs="24">
        <div class="dashboard-card" style="margin-bottom: 10px;">
          <div class="title">
            {{ $t("masterComponentsStatus") }}
          </div>
          <component-status :vendor="vendor" :region="region" :cluster="cluster" />
        </div>
      </el-col>

      <el-col :md="24" :lg="12" :sm="24" :xs="24">
        <div class="dashboard-card" style="margin-bottom: 10px;">
          <div class="title">
            {{ $t("nodeStatus") }}
          </div>

          <div v-if="!loading">
            <div class="node-progress">
              <div class="_inner" :style="{ width: this.healthNodeWidth + '%' }"></div>
            </div>

            <div class="node-status-text">
              <div class="ready">
                <span>{{ $t("nodeReady") }}：</span>
                <span class="ready-text">{{ item.node.ready }}</span>
              </div>

              <div class="not-ready">
                <span>{{ $t("nodeNotReady") }}：</span>
                <span class="not-ready-text">{{ item.node.total - item.node.ready }}</span>
              </div>
            </div>
          </div>

          <div v-else>
            <content-placeholders>
              <content-placeholders-text :lines="1" />
            </content-placeholders>
          </div>
        </div>
      </el-col>
    </el-row>

    <el-row :gutter="20">
      <el-col :md="24" :lg="12" :sm="24" :xs="24">
        <div class="dashboard-card" style="margin-bottom: 10px;">
          <div class="title">
            {{ $t("kubernetesSummary") }}
          </div>
          <kubernetes-resource :vendor="vendor" :region="region" :cluster="cluster" />
        </div>

        <div class="dashboard-card">
          <chart-tool
            :visibleObj="{ timeRange: true, interval: true }"
            :interval.sync="chartInterval"
            :timeRange.sync="timeRange"
            @timeRangeChange="timeRangeChange"
            @intervalChange="intervalChange"
            style="margin: 6px 0;"
          />

          <el-row v-if="!chartLoading" :gutter="20">
            <el-col :span="12" v-for="infra in infraChartList" :key="infra.name">
              <div class="g2-chart-title">{{ mapTitle[infra.name] }}</div>
              <div class="chart-container">
                <g2-plot :option="mapOption(infra)" type="Area" :ref="infra.name" v-if="infra.values.length > 0" />
                <result type="empty" v-else />
              </div>
            </el-col>
          </el-row>

          <el-row :gutter="20" v-else>
            <el-col :span="12" v-for="(item, index) in 4" :key="index" style="margin-bottom: 20px;">
              <content-placeholders :rounded="true">
                <content-placeholders-text :lines="1" />
                <content-placeholders-img />
              </content-placeholders>
            </el-col>
          </el-row>
        </div>
      </el-col>

      <el-col :md="24" :lg="6" :sm="24" :xs="24">
        <div class="dashboard-card">
          <div class="title">{{ $t("namespaceRank") }}</div>

          <el-radio-group v-model="namespaceRankBy" size="small">
            <el-radio-button label="cpu">CPU</el-radio-button>
            <el-radio-button label="memory">Memory</el-radio-button>
          </el-radio-group>

          <namespace-rank
            :vendor="vendor"
            :region="region"
            :cluster="cluster"
            :sortby="namespaceRankBy"
          ></namespace-rank>
        </div>
      </el-col>

      <el-col :md="24" :lg="6" :sm="24" :xs="24">
        <div class="dashboard-card">
          <div class="title">{{ $t("nodeAbstract") }}</div>
          <node-list :vendor="vendor" :region="region" :cluster="cluster" />
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import ChartTool from "@/components/ChartTool";
import provider from "@/mixins/provider";
import chartTool from "@/mixins/chart-tool";
import i18n from "@/lang";
import Result from "@/components/Result.vue";
import ComponentStatus from "./ComponentStatus";
import KubernetesResource from "./KubernetesResource";
import NamespaceRank from "./NamespaceRank";
import NodeList from "./NodeList";
import moment from "moment";

import { metircTarget } from "api/monitor";
import { getStep } from "utils";
import { clusters, clusterCost } from "api/monitor";

export default {
  components: {
    ChartTool,
    Result,
    ComponentStatus,
    KubernetesResource,
    NamespaceRank,
    NodeList
  },

  mixins: [provider, chartTool],

  props: {
    vendor: {
      type: String
    },
    cluster: {
      type: String
    },
    region: {
      type: String
    }
  },

  data() {
    return {
      chartInterval: false,

      mapTitle: {
        cluster_cpu_utilization: i18n.t("cluster_cpu_utilization"),
        cluster_memory_utilization: i18n.t("cluster_memory_utilization"),
        cluster_disk_utilization: i18n.t("cluster_disk_utilization"),
        cluster_pod_utilization: i18n.t("cluster_pod_utilization")
      },

      metricList: [
        "cluster_cpu_utilization",
        "cluster_memory_utilization",
        "cluster_disk_utilization",
        "cluster_pod_utilization"
      ],

      loading: true,
      chartLoading: true,
      costLoading: true,
      infraChartList: [],
      interval: null,

      // currentMonthCost: "",
      // previousMonthCost: "",
      healthNodeWidth: 0,
      namespaceRankBy: "cpu"
    };
  },

  methods: {
    metricTarget(metricList, unit) {
      return new Promise((resolve, reject) => {
        let [start, end] = this.timeRange;
        let step = getStep(end - start, 80);
        let query = metricList.join(",");

        metircTarget(
          { vendor: this.vendor, region: this.region, cluster: this.cluster },
          { query, start, end, step }
        ).then(response => {
          if (response.code === 0) {
            response.data.forEach(item => {
              let values = [];

              if (item.model && item.model[0] && item.model[0].values) {
                item.model[0].values.forEach(value => {
                  values.push({
                    time: value[0],
                    value: Number(Number(value[1] * unit).toFixed(1))
                  });
                });
              }

              item.values = values;
            });

            resolve(response.data);
          } else {
            reject(response.message);
          }
        });
      });
    },

    init() {
      this.initInfraMetric();
    },

    initInfraMetric() {
      this.chartLoading = true;
      this.metricTarget(this.metricList, 100).then(data => {
        this.infraChartList = data;

        this.chartLoading = false;
      });
    },

    intervalMetric() {
      this.interval = setInterval(() => {
        this.metricTarget(this.metricList, 100).then(data => {
          data.forEach(item => {
            if (this.$refs[item.name][0]) this.$refs[item.name][0].plot.changeData(item.values);
          });
        });
      }, 10000);
    },

    mapOption(item) {
      return {
        height: 154,
        forceFit: true,
        padding: [30, 20, 30, 50],

        data: item.values,
        xField: "time",
        yField: "value",
        smooth: true,
        yAxis: {
          min: 0,
          tickCount: 4,
          label: {
            visible: true,
            suffix: "%"
          },
          nice: true
        },

        xAxis: {
          type: "dateTime",
          grid: {
            visible: true
          },
          tickCount: 6,
          label: {
            formatter: value => {
              return moment(value * 1000).format("HH:mm");
            }
          }
        },

        tooltip: {
          visible: true,
          shared: true,
          showCrosshairs: true,
          showTitle: false,
          formatter: (time, value) => {
            return { name: moment(time * 1000).format("YYYY-MM-DD, HH:mm:ss"), value };
          }
        },

        areaStyle: {
          fill: "l(90) 0:#2676d4 1:#ffffff"
        }
      };
    },

    async getClustersInfo() {
      this.loading = true;

      let response = await clusters({ vendor: this.vendor, region: this.region, cluster: this.cluster });

      if (response.code === 0) {
        for (let item of response.data) {
          if (item.kind === this.vendor && item.region === this.region && item.name === this.cluster) {
            this.item = item;
            this.healthNodeWidth = this.item.node.percent * 100;
            this.loading = false;
            return;
          }
        }
      }
    }

    // clusterCost() {
    //   this.costLoading = true;

    //   clusterCost({ vendor: this.vendor, region: this.region, cluster: this.cluster }).then(response => {
    //     if (response.code === 0) {
    //       this.costLoading = false;
    //       this.currentMonthCost = response.data.currentMonthCost.toFixed(1);
    //       this.previousMonthCost = response.data.previousMonthCost.toFixed(1);
    //     }
    //   });
    // }
  },

  mounted() {
    this.getClustersInfo();
    // this.clusterCost();
    this.init();
  }
};
</script>

<style lang="scss" scoped>
@import "~@/styles/variables.scss";
@import "~@/styles/mixin.scss";

.cost-container {
  @include flex(space-between, center, nowrap);
  line-height: 42px;
  .money {
    color: #e09e1f;
    margin-right: 8px;
    font-size: 18px;
    font-weight: bolder;
  }

  .previous-month {
    font-size: 12px;
    color: $color-sub;
    @include text-overflow();
  }
}

.node-progress {
  @include progress();
  height: 14px;
  background-color: #e17a4c;
  border-radius: 4px;

  ._inner {
    border-radius: 4px;
    background-color: #257adf;
    border-right: 1px solid #fff;
  }

  margin-bottom: 10px;
}

.node-status-text {
  @include flex(space-between, center, nowrap);

  & > div {
    @include text-overflow();
    width: 50%;
    position: relative;
    text-indent: 12px;

    &::after {
      position: absolute;
      width: 6px;
      height: 6px;
      left: 0;
      top: 6px;
      content: "";
    }

    &.ready::after {
      background-color: #257adf;
    }

    &.not-ready::after {
      background-color: #e17a4c;
    }
  }

  font-size: 12px;
  color: $color-sub;

  .ready-text {
    color: #257adf;
  }

  .not-ready-text {
    color: #e17a4c;
  }
}

.chart-container {
  background-color: #f2f7f8;
  margin-bottom: 10px;
}
</style>
